module.exports = {
  siteTitle: 'Agash Navaranjan | agash.dev',
  siteDescription:
    'Agash Navaranjan is a technical leader with significant development experience in several different industries.',
  siteKeywords:
    'Agash, Navaranjan, Agash Navaranjan, KillerGasy, Dovetail, CTO, Blockchain. Developer, Tech Lead',
  siteUrl: 'https://agash.dev',
  siteLanguage: 'en_US',
  googleAnalyticsID: 'UA-160400006-1',
  googleVerification: '',
  name: 'Agash Navaranjan',
  location: 'London, UK',
  email: 'me@agash.dev',
  github: 'https://github.com/KillerGasy',
  socialMedia: [
    {
      name: 'GitHub',
      url: 'https://github.com/KillerGasy',
    },
    {
      name: 'Linkedin',
      url: 'https://www.linkedin.com/in/agash-navaranjan',
    },
  ],

  navLinks: [
    {
      name: 'About',
      url: '/#about',
    },
    {
      name: 'Experience',
      url: '/#jobs',
    },
    {
      name: 'Contact',
      url: '/#contact',
    },
  ],

  navHeight: 100,
  greenColor: '#F4442E',
  navyColor: '#874000',
  darkNavyColor: '#010001',

  srConfig: (delay = 200) => ({
    origin: 'bottom',
    distance: '20px',
    duration: 500,
    delay,
    rotate: { x: 0, y: 0, z: 0 },
    opacity: 0,
    scale: 1,
    easing: 'cubic-bezier(0.645, 0.045, 0.355, 1)',
    mobile: true,
    reset: false,
    useDelay: 'always',
    viewFactor: 0.25,
    viewOffset: { top: 0, right: 0, bottom: 0, left: 0 },
  }),
};
