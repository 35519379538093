import React from 'react';

const IconLogo = () => (
  <svg
    width="188px"
    height="188px"
    viewBox="0 0 188 188"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg">
    <title>AN SVG</title>
    <desc>Created with Sketch.</desc>
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Artboard" transform="translate(-113.000000, -112.000000)">
        <g id="Group" transform="translate(117.000000, 116.000000)">
          <circle id="Oval" stroke="#BDD4E7" strokeWidth="8" cx="90" cy="90" r="90"></circle>
          <text
            id="AN"
            fontFamily="HelveticaNeue, Helvetica Neue"
            fontSize="85"
            fontWeight="normal"
            letterSpacing="1.51785714"
            fill="#BDD4E7">
            <tspan x="30" y="122">
              AN
            </tspan>
          </text>
        </g>
      </g>
    </g>
  </svg>
);

export default IconLogo;
